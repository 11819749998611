<template>
  <div>
    <div class="smp-nearLiveMatchSelect-headline">
      {{ headline }}
    </div>
    <div
      class="smp-dropdownFilter-option smp-singleSelectFilter-option"
      v-for="option in options"
      :key="option.value"
      @click.prevent="$emit('select', option)">
      <div class="smp-singleSelectFilter-option-labelWrapper">
        <span class="smp-singleSelectFilter-option-label">
          {{ option.label }}
        </span>

        <span
          v-if="option.subLabel"
          class="smp-singleSelectFilter-option-subLabel">
          {{ option.subLabel }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headline: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
};
</script>
