<template>
  <div
    class="smp-nearLivePage smp-mainPage"
    v-if="isReady">
    <near-live-page-header />
    <near-live-timeline-view
      v-if="viewMode === 'timeline'" />

    <near-live-grid-view
      v-if="viewMode === 'grid'" />

    <near-live-posting-preview
      v-if="activePosting" />
  </div>

  <div
    class="app--loading"
    v-else>
    <fa-icon
      icon="spinner"
      spin />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import NearLivePageHeader from '@/components/near-live/NearLivePageHeader.vue';
import NearLivePostingPreview from '@/components/near-live/NearLivePostingPreview.vue';
import NearLiveTimelineView from '@/components/near-live/NearLiveTimelineView.vue';
import NearLiveGridView from '@/components/near-live/NearLiveGridView.vue';
import { logout } from '@/util/firebase';

export default {
  components: {
    NearLivePageHeader, NearLivePostingPreview, NearLiveTimelineView, NearLiveGridView,
  },
  computed: {
    ...mapState('nearLive', ['postings', 'match', 'activePosting', 'viewMode']),
    ...mapGetters('nearLive', ['isReady']),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('nearLive', ['bindNearLivePostings', 'loadSession']),
    async init() {
      await this.loadSession();
      this.bindNearLivePostings();
    },
    async logout() {
      await logout();
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

body {
  .smp-nearLivePage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &.overlay-open .smp-nearLivePage {
    > .smp-nearLivePage-header, .smp-nearLive-timelineView, .smp-nearLivePage-gridView {
      filter: blur(15px);
    }
  }
}
</style>
