<template>
  <div
    class="smp-nearLiveTimelinePosting"
    :class="`smp-nearLiveTimelinePosting--${positionClass}`">
    <div class="smp-card">
      <div
        v-if="previewImage"
        class="smp-nearLiveTimelinePosting-imageContainer"
        @click.prevent="openPreview">
        <img :src="previewImage">
      </div>
      <div class="smp-nearLiveTimelinePosting-content">
        <div
          class="smp-nearLiveTimelinePosting-comment"
          v-if="posting.comment"
          @click.prevent="openPreview">
          {{ teaser }}
        </div>
        <div class="smp-nearLiveTimelinePosting-footer">
          <div class="smp-postingCard-downloadCta flex-none">
            <download-button
              :posting="posting"
              button-classes="smp-button smp-button-light" />
          </div>
        </div>
      </div>
    </div>

    <div class="smp-nearLiveTimelinePosting-timelineMarker">
      <span class="line">
        {{ capturedTime }}
      </span>
      <span class="dot" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from '@/util/moment';
import DownloadButton from '@/components/near-live/NearLiveDownloadButton.vue';

export default {
  components: { DownloadButton },
  props: {
    posting: {
      type: Object,
      required: true,
    },
    match: {
      type: Object,
      required: true,
    },
  },
  computed: {
    positionClass() {
      return this.posting.team === this.match.teamA ? 'left' : 'right';
    },
    capturedTime() {
      let time = moment(this.posting.captured, moment.getDateTimeFormat());
      time = time.toUserTimezone();

      return time.format('HH:mm');
    },
    previewImage() {
      return this.posting.previewImage ?? null;
    },
    teaser() {
      const { comment } = this.posting;

      if (comment && typeof comment === 'string') {
        return comment.length > 100
          ? `${comment.substr(0, 100)}...`
          : comment;
      }

      return null;
    },
  },
  methods: {
    ...mapActions('nearLive', ['showPreview']),
    openPreview() {
      this.showPreview(this.posting);
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-nearLiveTimelinePosting {
  margin-bottom: @space-6;
  display: flex;
  align-items: flex-start;

  .smp-card {
    margin: 0 @space-6 0;
    display: flex;

    .smp-nearLiveTimelinePosting-imageContainer {
      overflow: hidden;
      cursor: pointer;
      position: relative;
      .flex-none;
      display: flex;
      align-items: center;

      img {
        display: block;
        max-width: 320px;
        max-height: 180px;
      }
    }

    .smp-nearLiveTimelinePosting-content {
      padding: @space-3;
      max-width: 200px;
      display: flex;
      flex-direction: column;

      .smp-nearLiveTimelinePosting-comment {
        .flex-auto;
        cursor: pointer;
      }
    }

    .smp-nearLiveTimelinePosting-footer {
      display: flex;
      align-items: flex-end;
      margin-top: @space-4;
    }
  }

  .smp-nearLiveTimelinePosting-timelineMarker {
    display: flex;
    align-items: center;
    position: relative;

    span.line {
      background: @color-secondary;
      color: @color-mono-100;
      padding: @space-2 @space-3 @space-1;
      .ms(0);
      position: absolute;
      .font-highlight;
    }

    span.dot {
      width: @nearLiveDotWidth;
      height: @nearLiveDotWidth;
      background: @color-mono-100;
      border-radius: 50%;
      border: 8px solid @color-secondary;
      transform: translateX(calc(50% + (@nearLiveTimelineWidth / 2)));
    }
  }

  &.smp-nearLiveTimelinePosting--left {
    transform: translateX(-50%);

    .smp-nearLiveTimelinePosting-imageContainer {
      border-top-left-radius: @defaultBorderRadius;
      border-bottom-left-radius: @defaultBorderRadius;
    }

    .smp-card {
      .smp-nearLiveTimelinePosting-footer {
        justify-content: flex-end;
      }
    }

    .smp-nearLiveTimelinePosting-timelineMarker {
      span.dot {
        transform: translateX(calc(50% + (@nearLiveTimelineWidth / 2)));
      }

      span.line {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        right: 0;
        padding-right: @nearLiveDotWidth / 2;
      }
    }
  }

  &.smp-nearLiveTimelinePosting--right {
    transform: translateX(50%);
    flex-direction: row-reverse;

    .smp-nearLiveTimelinePosting-imageContainer {
      border-top-right-radius: @defaultBorderRadius;
      border-bottom-right-radius: @defaultBorderRadius;
    }

    .smp-card {
      flex-direction: row-reverse;

      .smp-nearLiveTimelinePosting-footer {
        justify-content: flex-start;
      }
    }

    .smp-nearLiveTimelinePosting-timelineMarker {
      flex-direction: row-reverse;

      span.dot {
        transform: translateX(calc(-50% + (@nearLiveTimelineWidth / 2)));
      }

      span.line {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        left: 0;
        padding-left: @nearLiveDotWidth / 2;
      }
    }
  }
}
</style>
