<template>
  <div
    v-if="options.length > 0"
    class="smp-dropdownFilter smp-singleSelectFilter"
    :class="{'is-open': isOpen }">
    <div
      class="smp-dropdownFilter-toggle smp-singleSelectFilter-toggle smp-button smp-button-primary"
      :class="{'smp-singleSelectFilter-toggle--hasSubLabel': selectedOption.subLabel}"
      @click="toggle">
      <i class="mr2">
        <fa-icon :icon="['fas', 'futbol']" />
      </i>
      <div
        class="flex-auto smp-singleSelectFilter-labelWrapper"
        :class="{'smp-singleSelectFilter-labelWrapper--hasSubLabel': selectedOption.subLabel}">
        <span class="smp-singleSelectFilter-label">{{ selectedOption.label }}</span>
        <span
          v-if="selectedOption.subLabel"
          class="smp-singleSelectFilter-subLabel">
          {{ selectedOption.subLabel }}
        </span>
      </div>
      <div class="smp-dropdownFilter-toggleIndicator smp-singleSelectFilter-toggleIndicator">
        <fa-icon :icon="['fas', 'angle-down']" />
      </div>
    </div>

    <div
      v-show="isOpen"
      class="smp-dropdownFilter-content smp-singleSelectFilter-content">
      <div
        class="smp-dropdownFilter-options smp-singleSelectFilter-options"
        ref="optionsContainer">
        <near-live-match-select-section
          v-if="currentMatches.length > 0"
          :headline="$t('nearLive.matchSelect.currentMatches')"
          :options="currentMatches"
          @select="select($event)" />

        <near-live-match-select-section
          v-if="pastMatches.length > 0"
          :headline="$t('nearLive.matchSelect.pastMatches')"
          :options="pastMatches"
          @select="select($event)" />

        <near-live-match-select-section
          v-if="upcomingMatches.length > 0"
          :headline="$t('nearLive.matchSelect.upcomingMatches')"
          :options="upcomingMatches"
          @select="select($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { getTeamLabel } from '@/util/i18n';
import DropDownMixin from '@/mixins/DropDown';
import { sortArray } from '@/util/utils';
import moment from '@/util/moment';
import NearLiveMatchSelectSection from '@/components/near-live/NearLiveMatchSelectSection.vue';

export default {
  data() {
    return {
      containerClass: 'smp-singleSelectFilter',
    };
  },
  components: {
    NearLiveMatchSelectSection,
  },
  computed: {
    ...mapState('nearLive', ['match']),
    ...mapState(['teamList', 'currentLocale']),
    ...mapGetters(['matches']),
    ...mapGetters('nearLive', ['currentMatchesRange']),
    selectedOption() {
      const selected = this.options.find((option) => option.active);

      return selected || this.options[0];
    },
    options() {
      return sortArray(
        this.matches.map((match) => {
          const teamA = getTeamLabel(match.teamA, this.teamList, this.currentLocale);
          const teamB = getTeamLabel(match.teamB, this.teamList, this.currentLocale);

          return {
            id: match.id,
            kickoff: match.kickoff,
            value: match.id,
            label: `${teamA} : ${teamB}`,
            subLabel: match.kickoff,
            active: this.match && this.match.id === match.id,
          };
        }),
        'subLabel',
        'asc',
      );
    },
    currentMatchesStart() {
      const baseDate = moment.utc();
      const start = baseDate.clone();

      if (this.currentMatchesRange) {
        if (this.currentMatchesRange === 'weekend') {
          const currentDay = baseDate.isoWeekday();
          if (currentDay > 5) {
            start.subtract(currentDay - 5, 'days');
          } else if (currentDay === 1) {
            start.subtract(3, 'days');
          } else if (currentDay < 5) {
            start.add(5 - currentDay, 'days');
          }

          return start.startOf('day');
        }
      }

      return start.startOf('day');
    },
    currentMatchesEnd() {
      if (this.currentMatchesRange) {
        if (this.currentMatchesRange === 'weekend') {
          return this.currentMatchesStart.clone().add(3, 'days').endOf('day');
        }
      }

      return this.currentMatchesStart.clone().endOf('day');
    },
    currentMatches() {
      const start = this.currentMatchesStart;
      const end = this.currentMatchesEnd;

      return this.options.filter((match) => {
        const kickoffDate = moment(match.kickoff);
        return kickoffDate.isAfter(start) && kickoffDate.isBefore(end);
      });
    },
    upcomingMatches() {
      const currentMatchIds = this.currentMatches.map((match) => match.id);

      return this.options.filter((match) => (
        moment(match.kickoff).isAfter(moment.utc().startOf('day'))
        && !currentMatchIds.includes(match.id)
      ));
    },
    pastMatches() {
      const currentMatchIds = this.currentMatches.map((match) => match.id);
      const upcomingMatchIds = this.upcomingMatches.map((match) => match.id);

      return this.options.filter((match) => (
        !currentMatchIds.includes(match.id)
        && !upcomingMatchIds.includes(match.id)
      ));
    },
  },
  mixins: [DropDownMixin],
  methods: {
    select(option) {
      this.$emit('input', option);
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";
.smp-nearLiveMatchSelect-headline {
  padding: @space-3;
  background: lighten(@color-primary, 10);
  font-weight: bold;
  white-space: nowrap;
}
</style>
