<template>
  <div class="smp-nearLive-timelineView">
    <div
      class="smp-nearLivePage-match">
      <div
        class="smp-nearLivePage-teams"
        v-if="match">
        <div class="smp-nearLivePage-team">
          <team :code="match.teamA" />
        </div>

        <div class="smp-nearLivePage-team">
          <team
            :code="match.teamB"
            flag-position="right" />
        </div>
      </div>
    </div>

    <div
      class="smp-nearLivePage-ticker"
      v-if="postings && postings.length > 0">
      <div class="smp-nearLivePage-tickerStart" />

      <near-live-timeline-posting
        v-for="posting in postings"
        :key="posting.id"
        :posting="posting"
        :match="match" />
    </div>
    <div
      v-else
      class="flex justify-center mt6 ms3">
      {{ $t('nearLive.noResults') }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NearLiveTimelinePosting from '@/components/near-live/NearLiveTimelinePosting.vue';
import Team from '@/components/Team.vue';

export default {
  components: { NearLiveTimelinePosting, Team },
  computed: {
    ...mapState('nearLive', ['postings', 'match']),
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-nearLive-timelineView {
  .flex-auto;
  overflow-y: auto;

  .smp-nearLivePage-match {
    padding: @space-6 0 @space-6;

    .smp-nearLivePage-teams {
      display: flex;
      justify-content: center;
      .ms(4);

      > .smp-nearLivePage-team {
        padding: 0 @space-5;
        position: relative;
        width: 50%;
        display: flex;

        &:first-child {
          text-align: right;
          justify-content: flex-end;

          span.flag {
            margin-right: @space-4;
          }

          &:after {
            content: ":";
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(50%);
          }
        }

        &:last-child {
          justify-content: flex-start;

          span.flag {
            margin-left: @space-4;
          }
        }
      }
    }
  }

  .smp-nearLivePage-ticker {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 @space-8 0;
    min-height: 100%;

    .smp-nearLivePage-tickerStart {
      background: @color-mono-100;
      border-radius: 50%;
      width: @nearLiveDotWidth;
      height: @nearLiveDotWidth;
      margin-bottom: @space-6;
      position: relative;
      top: -@nearLiveDotWidth;
      left: @nearLiveTimelineWidth;
      border: 8px solid @color-secondary;
      box-shadow: 0 0 0 0 @color-secondary;
      transform: scale(1);
      animation: pulse 2s infinite;
    }

    @tickerLineColor: fade(@color-secondary, 100);
    &:before {
      content: "";
      width: @nearLiveTimelineWidth;
      position: absolute;
      top: 0;
      height: 100%;
      left: 50%;
      background-image: linear-gradient(180deg, @tickerLineColor, @tickerLineColor 75%, transparent 75%, transparent 100%);
      background-size: 1px 10px;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 fade(@color-secondary, 70);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px fade(@color-secondary, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 fade(@color-secondary, 0);
  }
}
</style>
